.gm-spinner-50 {
  width: 50px;
  height: 50px;
}

@keyframes ldio-413e85d3ydp {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-413e85d3ydp div {
  box-sizing: border-box !important;
}
.ldio-413e85d3ydp > div {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 14px;
  left: 14px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: rgba(0, 84, 161, 0.7516129032258064) transparent
    rgba(0, 84, 161, 0.7516129032258064) transparent;
  animation: ldio-413e85d3ydp 0.6711409395973155s linear infinite;
}
.ldio-413e85d3ydp > div:nth-child(2) {
  border-color: transparent;
}
.ldio-413e85d3ydp > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-413e85d3ydp > div:nth-child(2) div:before,
.ldio-413e85d3ydp > div:nth-child(2) div:after {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 24px;
  background: rgba(0, 84, 161, 0.7516129032258064);
  border-radius: 50%;
  box-shadow: 0 64px 0 0 rgba(0, 84, 161, 0.7516129032258064);
}
.ldio-413e85d3ydp > div:nth-child(2) div:after {
  left: -8px;
  top: 24px;
  box-shadow: 64px 0 0 0 rgba(0, 84, 161, 0.7516129032258064);
}
.loadingio-spinner-dual-ring-mnt0rrug7d {
  width: 50px;
  height: 50px;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
}
.ldio-413e85d3ydp {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.5);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-413e85d3ydp div {
  box-sizing: content-box;
}
.reload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
}
