.alert {
  display: flex;
  justify-content: space-between;

  border-radius: 6px;
  margin-bottom: 10px;
  padding: 5px 10px;

  background-color: var(--danger);
  color: var(--neutral-light);
}

.success {
  background-color: var(--success);
}

.info {
  background-color: var(--neutral-light);
  border-left: 5px solid var(--info);
  border-radius: 8px;
  color: var(--info);
  align-items: center;
}

.closeBtn {
  cursor: pointer;
}

.info .closeBtn {
  background-color: var(--info);
  border-radius: 50%;
}
