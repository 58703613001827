.spinnerWrapper {
  width: 100%;
  height: 50vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .topupModal {
    min-width: unset;
    width: 500px;
  }
}
