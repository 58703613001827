.wrapper {
  height: 100%;
  width: 100%;

  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-dark-5);
  padding: 20px 20px 0;
}

.wrapper header {
  display: flex;
  gap: 10px;
}

.wrapper section {
  min-height: 200px;
}

.wrapper section.noData {
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--neutral-dark-5);
  font-size: 24px;
}

.title {
  font-size: 20px;
  font-weight: bold;
}

.item {
  display: flex;
  gap: 20px;
  width: 100%;
}

.itemTitle {
  border-right: 1px solid var(--neutral-dark-5);
  min-width: 150px;
  padding: 10px 0;
  padding-right: 10px;
  text-align: right;
}

.valueBar {
  min-width: 70%;
  max-height: 20px;

  background-color: var(--neutral-dark-7);
  margin: 10px 0;
}

.table {
  border-collapse: collapse;
}

.table th,
.table td {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

section.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--neutral-dark-5);
  font-size: 24px;
}

.table th:not(:first-child),
.table td:not(:first-child) {
  text-align: center;
}
