.gm-datatable-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #d7eeff;
  padding: 10px 0;
}

.gm-datatable-item:first-child {
  padding-top: 0;
}

.gm-datatable-checkbox {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.gm-datatable-checkbox input[type='checkbox'] {
  width: 100%;
  height: 100%;
  padding: 0;
  border: 1px solid #99ccf1;
  background-color: #ffffff;
}

.gm-datatable-content {
  flex-grow: 1;
  margin-right: 20px;
}

.gm-datatable-item-badge {
  flex-shrink: 0;
  max-width: 100px;
  border-radius: 10%;
  text-align: center;
}

.gm-datatable-item-title {
  font-size: 16px;
  font-weight: bold;
  color: #19415e;
}

.gm-datatable-item-tagline {
  font-size: 14px;
  color: #19415e;
}

.gm-datatable-item-metadata {
  font-size: 13px;
  color: hsla(205, 58%, 23%, 0.7);
}
