.outerWrapper {
  overflow: hidden;
}

.code-wrapper {
  max-width: 100%;
  overflow-x: scroll;

  border-radius: 5px;
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #d7eeff;

  background-color: #f4f8fb;
}

.website-integration {
  border-right: 1px solid #f5f5f5;
}

.integration-nav {
  padding-left: 4px;
}

.website-integration,
.integration-nav {
  max-height: 500px;
  overflow-y: scroll;
  margin-top: -15px;
  margin-bottom: -15px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}

.snippet-generator-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.snippet-form {
  padding: 0 !important;
}

.form-group-card {
  background-color: var(--neutral-dark-7);
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}
