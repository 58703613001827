.container {
  padding: 10px;
}

.accordion,
.accordionPanel {
  border-radius: 0px !important;
}

.accordionPanel h2 {
  padding: 0 !important;
}

.accordionPanel h2 button {
  border-radius: 0px !important;
  background-color: var(--neutral-dark-6);
  padding: 20px 10px !important;
  font-weight: 500;
}

.accordionPanel h2 button:hover,
.accordionPanel h2 button[aria-expanded='true'] {
  background-color: var(--blue-light-6) !important;
  color: var(--blue-dark);
}

.accordionContent {
  padding-top: 15px;
  min-height: 500px;
}

.noMargin {
  margin: 0;
}

.date_picker div {
  width: initial;
}

#input-file-upload {
  display: none;
}

.error {
  color: var(--danger);
  font-size: 12px;
  margin-bottom: 0;
}

.pdf {
  margin-top: 0.5em;
  margin-left: -5px;
}
.file {
  margin-left: 0.7em;
  margin-top: 0.4em;
  font-size: 13px;
}
.btn {
  margin-left: 18%;
  cursor: pointer;
}
.attach {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 8px !important;
  margin-top: 0.5em;
}
.picker {
  width: 100%;
  position: relative;
}
.error,
.testAudience {
  color: var(--danger);
}
.audienceChecked {
  background-color: var(--danger);
}
.audienceCheckBox {
  border-color: var(--danger);
}

@media screen and (min-width: 768px) {
  .pdf {
    margin-bottom: 1em;
  }
}

@media screen and (min-width: 1028px) {
  .container {
    padding: 0px;
  }

  .repetition {
    display: grid;
    grid-template-columns: 3fr 5fr;
    gap: 20px;
  }
}
