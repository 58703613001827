div.react-flow__attribution {
  visibility: hidden !important;
}

.react-flow__controls {
  box-shadow: none !important;
}

.react-flow__container {
  position: unset;
}
