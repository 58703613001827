/**/
.content-header {
  /* border-bottom: 1px solid #dedede; */
  width: auto;
  float: left;
}
.content-header-actions {
  width: auto;

  font-size: 1.15em;
}

.list-stats-wrapper {
  height: 200px;
}

.graph-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.graph-actions > div:first-child {
  margin-right: 0px;
}

.graph-action-title {
  font-size: 20px;
}

.graph-filter {
  border: 1px solid #c4c4c4;
  color: black;
  font-weight: normal;
  font-size: small;
}

.icon-style {
  color: #c4c4c4;
  border-left: 1px solid #c4c4c4;
}

.audience-dashboard-btn-wrapper {
  display: flex;
  justify-content: flex-end;
}

.audience-contact-btn {
  width: fit-content;
}
@media screen and (min-width: 481px) {
  .audience-dashboard > div:first-child {
    /* float: right; */
  }

  .audience-dashboard > div:last-child {
    /* float: left; */
  }

  .block {
    max-width: 30%;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #d3d3d3;
  }
}

@media screen and (min-width: 1280px) {
}
