/** Card */
.wrapper {
  border: 1px solid var(--neutral-dark-5);
  border-radius: 10px;
  background-color: var(--neutral-light);

  display: flex;
  gap: 2.5%;
}

.wrapper p {
  font-size: 14px;
}

/** Main Section */
.mainArea {
  padding: 20px;
  width: 55%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}

.mainContent h2 {
  margin-bottom: 20px;
}

.mainFooter {
  width: auto;

  display: flex;
  align-items: center;
  gap: 10px;

  color: var(--red-primary);
  font-weight: 500;

  cursor: pointer;
}

/** Guide Section */
.guidesArea {
  border-left: 1px solid var(--neutral-dark-5);
  width: 42.5%;

  display: flex;
  flex-direction: column;
}

.guide {
  border-bottom: 1px solid var(--neutral-dark-5);
  padding: 20px;
}

.guide:last-child {
  border-bottom: none;
}

.guideContentArea {
  display: flex;
  gap: 10px;
}

.guideIcon {
  color: var(--neutral-dark-4);
}

.isCompleted {
  color: var(--blue-primary);
}

.guideContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.guideContent h5 {
  margin: 0;
}

.guideAction {
  display: flex;
  gap: 20px;
}

/** CTA */
.ctaWrapper {
  display: flex;
  align-items: center;
  gap: 15px;

  color: var(--blue-primary);
  cursor: pointer;
}
