.badge {
  border-radius: 3px;
  padding: 3.5px 10px;
  font-size: 12px;
  background: var(--neutral-dark-6);
  color: var(--neutral-dark-2);
  align-items: center;
}

.green {
  background: var(--green-transparent-15);
  color: var(--green-primary);
}

.red {
  background: var(--red-transparent-15);
  color: var(--red-primary);
}

@media screen and (min-width: 768px) {
  .importsModal {
    width: 600px;
  }
}
