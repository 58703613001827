.wrapper {
  height: 100%;
  background-color: var(--neutral-light);
}

.content {
  padding: 10px 20px;
}

.wrapper > header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;

  background-color: var(--neutral-light);
}

.contact-row-wrapper {
  display: flex;
  gap: 10px;
}

.contact-row-actions {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 10px;
}

.btn {
  width: 70%
}

.remove-icon {
  display: flex;
  align-items: center;
  color: var(--neutral-dark-4);
  cursor: pointer;
  max-width: 30px;
}

@media screen and (min-width: 768px) {

  .contact-row-actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-top: 10px;

    float: right;
    width: 50%;
  }

  .btn {
    width: 100%;
  }
}

@media screen and (min-width: 1280px) {
  .content {
    max-width: 900px;
    padding: 10px 0px 10px 50px;
  }
}
