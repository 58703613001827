.journeyMap {
  padding: 10px;
  width: 100%;
  height: calc(100vh - 50px);
  background-color: var(--neutral-light);
}

.summary {
  background: #fff;
  border-radius: 5px;
  font-size: 13px;
  max-width: 250px;
  padding: 20px;
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;
}

.formWrapper {
  min-width: 500px;
}

.formWrapper > div {
  margin-bottom: 20px;
}

.button,
.add_button {
  width: max-content;
  position: absolute;
  top: 50%;
  bottom: 50%;
}

.button:hover,
.button:focus,
.add_button:hover,
.add_button:focus {
  opacity: 0.9;
  outline: none;
}

/* TREE */
.layoutPath {
  stroke: var(--neutral-dark-4) !important;
}

.stepWrapper {
  display: flex;
  align-items: center;
  padding: 10px;
  width: max-content;
}

.handle {
  position: unset;
}

.step {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid var(--neutral-dark-5);
  border-left: 3px solid var(--success);
  background-color: var(--neutral-light);

  padding: 10px;
}

.stepHeader {
  display: flex;
  justify-content: space-between;
  min-width: 200px;
  color: var(--neutral-dark-3);
}

.stepTitle {
  font-size: 16px;
  font-weight: bold;
  color: var(--neutral-dark-2);
  padding-top: 5px;
}

.stepDescription {
  color: var(--neutral-dark-3);
  margin-bottom: 0;
  min-width: 250px;
}

.connector {
  width: 100px;
  border-color: var(--neutral-dark-4);
}

.addStepButton {
  width: max-content;
  padding: 5px 20px;
  background-color: var(--neutral-light);
}

.addStepButton:focus,
.addStepButton:hover {
  opacity: 0.9;
  outline: none;
}

.modalBody {
  width: auto;
  min-width: 0;
}
