/**/
.dialog-veil {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.25);
  z-index: 999;
}

.dialog-content-wrapper {
  width: 100%;
  max-width: 480px;
  border-radius: 8px;
  background-color: #ffffff;
}

.dialog-header {
  padding: 15px 15px 0 15px;
}

.dialog-message {
  padding: 0 25px;
  font-size: 1.05rem;
  text-align: center;
}

.dialog-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 20px;
  padding: 0 25px 25px 25px;
}

.dialog-actions > button:first-child {
  margin-right: 10px;
}
