:root {
  --blue-dark: #003b71;
  --blue-transparent: #65b5ff41;
  --blue-light-6: #2b99ff15;
  --blue-light-5: #2b99ff30;
  --blue-light-3: #2b99ff73;
  --blue-light-1: #2b99ff85;
  --blue-primary: #2b99ff;

  --green-primary: #66b107;
  --green-transparent-15: #66b10715;

  --orange-primary: #ffaa2b;
  --orange-transparent-15: #ffaa2b15;

  --red-primary: #e36b6b;
  --red-transparent-15: #e36b6b15;

  --neutral-dark-0: #000000;
  --neutral-dark-1: #252525;
  --neutral-dark-2: #3f3f3f;
  --neutral-dark-3: #a0a0a0;
  --neutral-dark-4: #c4c4c4;
  --neutral-dark-5: #dddddd;
  --neutral-dark-6: #f5f5f5;
  --neutral-dark-7: #fbfbfb;
  --neutral-light: #ffffff;

  --purple: #9b447e;
  --danger: #e36b6b;
  --success: #71a05a;
  --info: #448ba2;
}

.text-blue-primary {
  color: #2a99ff;
}

.text-orange-primary {
  color: #ffbf60;
}

.text-danger {
  color: #e36b6b;
}

.text-success {
  color: #71a05a;
}

.text-info {
  color: #448ba2;
}
