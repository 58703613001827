.datepicker,
.datepicker:hover {
  height: 40px !important;
  border-radius: 50px !important;
  border: 1px solid var(--blue-light-5) !important;
  background-color: var(--blue-light-6) !important;
}

.datepicker input {
  border-radius: 50px !important;
  background-color: transparent !important;
}

.datetimepicker {
  border: 1px solid var(--neutral-dark-5) !important;
  background-color: var(--neutral-light);
  height: 40px !important;
}

.datetimepicker input {
  border-radius: 8px !important;
  background-color: transparent !important;
  line-height: 40px !important;
}

.error {
  color: var(--red-primary);
  font-size: 12px;
}
