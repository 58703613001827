.wrapper {
  height: 100vh;
  background-color: var(--neutral-dark-6);
  padding: 25px;
}

.inputWrapper {
  max-height: 93.5vh;
  overflow-y: auto;
}
