/**/
.sideNavWrapper {
  position: fixed;
  top: 0;
  left: -100%;

  height: 100%;
  width: 100%;

  overflow-y: auto;
  z-index: 99;
}

.link {
  display: flex;
  align-items: center;
}

.link:hover {
  text-decoration: none;
}

.appSideNav {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  padding-top: 20px;
}

.appSideNav header {
  display: flex;
  flex-direction: row;
  align-content: center;

  height: 50px;
  width: 100%;

  background-color: #ffffff;
  padding: 10px;
}

.siteIcon {
  height: fit-content;
  align-self: center;
}

.siteName {
  color: var(--blue-primary);
}

.siteName div {
  margin-top: 3px;
}

.siteName,
.siteDot {
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  font-family: DMSerifDisplay;
}

.siteDot {
  color: #ffbf60;
}

.appSideNav section {
  width: 100%;
  padding: 5px 0;
  margin-top: 15px;
}

.appSideNav button {
  background-color: var(--blue-primary);
  color: var(--neutral-light);
  border: none;
  border-radius: 30px;
  padding: 12px;
  text-align: center;
  margin: 20px;
}

.appSideNav button:focus {
  border: none;
  outline: none;
}

.appSideNav .cta {
  display: grid;
}

.appSideNav .cta a {
  padding: 15px 20px;
  color: #555;
  border-top: 1px solid #ddd;
  text-align: center;
}

.appSideNav .cta .text,
.appSideNav .icon {
  margin-right: 8px;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .appSideNav button {
    padding: 8px;
  }
}

@media screen and (min-width: 768px) {
  .appSideNav {
    width: 30%;
    border-right: 1px solid var(--neutral-dark-5);
  }

  .appSideNav header {
    padding-left: 11.5px;
  }

  .refillModal {
    width: 600px;
  }
}

@media screen and (min-width: 1280px) {
  .sideNavWrapper {
    float: left;
    position: unset;
    margin: 0;
    width: 20%;
  }

  .appSideNav header {
    padding: 15px;
  }

  .siteName {
    display: unset;
    line-height: 24px;
  }

  .appSideNav {
    min-width: 15%;
    max-width: 100%;
    height: 100%;
    width: 100%;
  }

  .appSideNav button {
    padding: 12px;
  }

  .refillModal {
    width: 800px;
  }
}

@media screen and (min-width: 1500px) {
  .sideNavWrapper {
    width: 15%;
  }
}
