.wrapper {
  max-height: 400px;
  overflow-y: scroll;
}

@media screen and (min-height: 768px) {
  .wrapper {
    max-height: unset;
  }
}
