.layout {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;

  height: 100%;
}

.cardHeader {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding: 15px 15px 10px;
}

.avatar {
  width: 24px;
  height: 24px;
}

.cardHeader span {
  margin-left: 5px;
}

.cardBody {
  padding: 15px 20px 20px;
}

.cardBody p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  min-height: 60px;
  color: #000;
}

.extrasWrapper {
  padding: 0 20px 20px 20px;
}

.amountDetails {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.amountDetails div {
  width: auto;
}

.amountDetails .amount {
  font-size: 14px;
  font-weight: 600;
}

.amount span:last-child {
  color: #66b107;
}

.duration {
  font-size: 10px;
  color: #8b8b8b;
}

.modalWidth {
  max-width: 800px;
}

.bold {
  font-weight: 600;
  color: #000;
}

.actions {
  display: flex;
  justify-content: space-between;
  row-gap: 10px;
}

.actions > button {
  width: 82.5%;
}

.deactivateBtn {
  border: none;

  background-color: var(--red-primary);
}

.moreBtn {
  border-radius: 5px;
  width: 40px;
  height: 40px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  background-color: var(--neutral-dark-5);
}

.webForm {
  width: 100%;
  max-width: 800px;
  max-height: 600px;
  padding: 20px;
}

.alertContainer {
  position: fixed;
  bottom: 10px;
  right: 0;
  padding: 0 5%;
}

@media screen and (min-width: 768px) {
  .configModal {
    min-width: unset;
  }

  .purchaseModal {
    min-width: unset;
    width: 500px;
  }
}
