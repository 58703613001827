.virtualText {
  color: var(--blue-primary);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
}

/**/
.details {
  padding: 10px 12px;
  border-radius: 5px;
  background-color: var(--blue-light-6);
  border: 1px solid var(--blue-light-1);
}

.summaryTitle {
  font-size: 20px;
  font-weight: bold;
  border-bottom: 2px solid var(--neutral-dark-3);
  padding-bottom: 5px;
}

.datepicker {
  border: 1px solid var(--neutral-dark-4) !important;
  border-radius: 5px !important;
  height: 40px !important;
}

.error {
  font-size: 12px;
  color: var(--red-primary);
}
