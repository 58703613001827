.summary {
  border: 1px solid var(--blue-light-3);
  border-radius: 8px;
  padding: 15px;

  background-color: var(--blue-light-6);
}

.summary > div {
  display: flex;
  justify-content: space-between;
}

.lineItem {
  font-size: 14px;
}
