.wrapper {
  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-dark-5);
  border-radius: 8px;
  padding: 20px;
}

.icon {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  color: var(--neutral-dark-3);
}

.datepicker,
.datepicker:hover {
  border-radius: 8px !important;
  border: 1px solid var(--blue-light-5) !important;
  background-color: var(--blue-light-6) !important;
  height: 40px !important;
}

.datepicker input {
  border-radius: 50px !important;
  background-color: transparent !important;
}
