.upload-container {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.preview-box {
  height: 200px;
  margin-top: 10px;
  text-align: center;
  position: relative;
  border-radius: 10px;
}

.preview-box img {
  display: block;
  height: 100%;
  width: 100%;
  background-size: cover;
}
.img-info {
  /* margin: 10px 0; */
  color: var(--info);
}

#form-file-upload {
  text-align: center;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  background-color: var(--neutral-dark-6);
  border: 1px solid var(--neutral-dark-5);
  border-radius: 0 0 10px 10px;
  font-size: 16px;
  line-height: 24px;
  padding-top: 14px;
  width: 100%;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}
#label-file-upload img {
  width: 50px;
}
#label-file-upload span {
  text-decoration: underline;
}

.upload-button {
  cursor: pointer;
  border: none;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
