.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--neutral-dark-7);
  flex-direction: column;
}

.wrapper > div {
  display: grid;
  max-width: 796px;
}

.header {
  margin-bottom: 40px;
  display: flex !important;
  align-items: center;
  cursor: pointer;
  width: 160px;
}
.header > svg {
  display: inline;

  margin-right: 10px;
}

.siteName {
  color: var(--blue-primary);
  cursor: pointer;
  font-size: 22px;
  font-weight: 600;
  font-family: DMSerifDisplay;
  display: inline;
}
.siteName > .stub {
  color: var(--orange-primary);
  letter-spacing: 1px;
}
