.rootWrapper {
  width: max-content;
}

.rootWidgetWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 150px;
}

.rootCardsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 400px;
}

.addEntryBtn {
  width: max-content;
}

.addBtn {
  width: 350px;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  border: 1px solid var(--blue-primary);
  border-radius: 50%;
  color: var(--blue-primary);
  padding: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
}
