.tox-editor-header {
  z-index: 0 !important;
}

.tox.tox-tinymce {
  border: 1px solid var(--neutral-dark-5) !important;
  border-bottom: none !important;
  border-top: none !important;
}

.tox-toolbar__primary {
  height: 60px !important;
  background: none !important;
  /* background-color: #F4F8FB !important; */
  border: none !important;
  border-bottom: 1px solid var(--neutral-dark-5) !important;

  z-index: 0 !important;
}

.tox-toolbar__overflow.tox-toolbar__overflow--open {
  background: none !important;
}

.tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
  border-right: none !important;
}

.tox-pop__dialog {
  display: none !important;
}

.tox-statusbar {
  display: none !important;
}

.wildcard-list ol {
  width: 350px;
  padding-left: 20px;
}

.wildcard-list li {
  width: 100%;
  padding: 0;
  margin-left: 0;
}

.tox .tox-tbtn svg {
  fill: #444444 !important;
}

/**/
.tox-collection__group {
  width: 200px !important;
}
