.keyWrapper {
  display: flex;
  gap: 15px;
  align-items: center;
  
  color: var(--neutral-dark-3);
}

.keyDisplay {
  color: var(--neutral-dark-2);
  border: 1px solid var(--neutral-dark-5);
  
  line-height: 40px;
  padding: 0 15px;
}
