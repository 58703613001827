.wrapper {
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 10px;
  width: 100%;

  display: flex;
  justify-content: space-between;

  background-color: var(--neutral-dark-6);
  border: 1px solid var(--neutral-dark-5);
}

.wrapper:last-child {
  margin-bottom: 0;
}

.title {
  font-weight: bold;
}

.actions {
  display: flex;
  gap: 15px;

  color: var(--neutral-dark-4);
}

.trashIcon {
  color: var(--danger);
}
