.member-area-wrapper {
  width: 100%;
  height: 100vh;

  background-color: #ffffff;
}

.app-body {
  height: 100vh;
  width: 100%;
}

.app-content {
  height: 100%;
  width: 100%;

  padding: 0px;
  padding-bottom: 20px;
  overflow-y: scroll;
}

.graph-wrapper {
  margin-top: 10px;
  padding: 10px;
}

.graph-wrapper * {
  width: auto;
  float: unset;
}

span.material-icons {
  font-size: 22px;
}

@media screen and (min-width: 481px) {
  .graph-wrapper {
    height: auto;
  }
}

@media screen and (min-width: 768px) {
  .app-content {
    position: unset;
    width: 100%;
    padding: 0 25px;
    background: var(--neutral-dark-6);
  }
}

@media screen and (min-width: 1280px) {
  .app-content {
    position: unset;
    width: 80%;
    min-height: 100vh;
    overflow-y: scroll;
  }
  .graph-wrapper {
    height: auto;
  }
}

@media screen and (min-width: 1500px) {
  .app-content {
    width: 85%;
    padding: 0 150px;
  }
}
