.bar,
.wrapper {
  border-radius: 50px;
}

.wrapper {
  height: 16px;
  width: 100%;
  
  background-color: var(--neutral-dark-6);
}

.bar {
  height: 100%;
  background-color: var(--blue-primary);
}
