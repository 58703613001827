.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.responseSectionHeader {
  display: flex;
  justify-content: space-between;

  color: var(--neutral-dark-3);
  text-transform: uppercase;
}

.responseSectionHeader > .icon {
  color: var(--blue-primary);
}

.responses {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.btnWrapper {
  border-top: 1px solid var(--neutral-dark-5);
  padding-top: 25px;
}

.responseModal {
  min-width: 20%;
}
