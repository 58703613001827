.widget {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.wrapper {
  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-dark-5);
  border-left: 5px solid var(--orange-primary);
  border-radius: 8px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.content {
  padding: 15px;
  min-height: 80px;
  width: 350px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.statWrapper {
  padding: 10px;
  background-color: var(--neutral-dark-6);
}

.green {
  border-left-color: var(--success);
}

.grey {
  border-left-color: var(--neutral-dark-3);
}

.icon {
  color: var(--neutral-dark-3);
}

#vertLine {
  align-self: center;
  border-left: 1px solid var(--neutral-dark-3);
  height: 50px;
}

.addBtn {
  align-self: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--blue-primary);
  border-radius: 50%;
  color: var(--blue-primary);

  display: flex;
  align-items: center;
  justify-content: center;
}

.disabledBtn {
  border-color: var(--neutral-dark-3);
  color: var(--neutral-dark-3);
}

.exitBtn {
  width: fit-content;
  font-size: 14px;
  padding: 5px 10px;

  height: unset;
  line-height: unset;
}

.exitTriggers {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.exitTriggerItem {
  border-left: 2px solid var(--orange-primary);
  padding-left: 15px;

  display: flex;
  justify-content: space-between;
}
