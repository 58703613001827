/**/
.wrapper {
  position: sticky;
  top: 0;
  left: 0;

  width: 100%;
  height: 50px;
  padding: 10px 0px;

  z-index: 2;
}

.wrapper > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
}

.menuIcon {
  float: left;
  margin-right: 10px;
  margin-top: 2px;
}

.title {
  width: fit-content;
  min-width: 50%;
  line-height: normal;
  font-weight: 400;
  font-size: 20px;
  text-overflow: ellipsis;
  font-family: DMSerifDisplay;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: fit-content;
  color: var(--neutral-dark-3);
}

.actions > * {
  margin-left: 5px;
}

.profileIcon {
  color: var(--blue-primary);
}

.wallet-balance-item {
  padding: 0;
}

.wallet {
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid var(--neutral-dark-6);
  color: var(--success);
  padding: 20px 0 0 0;
  width: 100%;
}

.wallet > small {
  width: 100%;
  line-height: 10px;
  color: var(--neutral-dark-2);
}

.wallet > span {
  /* line-height: 10px; */
  margin-top: 10px;
}

.greenBadge {
  background-color: #66b107;
  border: 1px solid #66b107;
  font-size: 14px;
  padding: 3px 32px;
  border-radius: 50px;
}

.optionsModal {
  max-width: 615px;
  min-width: 30%;
  width: 80%;
}

.pageTitle {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .wrapper {
    margin: 10px 0;
  }

  .title {
    font-size: 24px;
  }

  .actions > * {
    margin-left: 8.5px;
  }

  .importsModal {
    width: 600px;
  }

  .greenBadge {
    padding: 2px 15px;
    width: 120px;
  }
}

@media screen and (max-width: 767px) {
  .greenBadge {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .menuIcon {
    display: none;
  }
}
