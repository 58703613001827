.detailsWrapper {
  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-dark-5);
  border-radius: 8px;
}

.detailsWrapper header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 20px;
  font-weight: bold;

  border-bottom: 1px solid var(--neutral-dark-5);
  padding: 10px 20px;
}

.statSummary {
  padding: 20px;
}

.item {
  margin-bottom: 20px;
}

.item p {
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
}

.item > p:first-child {
  color: var(--neutral-dark-3);
  font-size: 12px;
  text-transform: uppercase;
}

.item ul {
  padding: 0;
}

.item li {
  text-align: left;
  margin: 0;
  padding: 0;
}

.required {
  color: var(--danger);
}
