.greenBadge,
.dangerBadge {
  border-radius: 3px;
  padding: 3.5px 10px;
  font-size: 12px;
  color: var(--neutral-light);
  align-items: center;
}

.greenBadge {
  background: var(--success);
}

.dangerBadge {
  background: var(--danger);
}

.readMore {
  display: flex;
  justify-content: center;
  color: var(--blue-primary);
}
