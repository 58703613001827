.wrapper {
  height: 100vh;
  padding: 20px 0;
  background-color: #ffffff;
}

.header {
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 160px;
}

.header > svg {
  margin-right: 10px;
}

.siteName {
  color: var(--blue-primary);
  cursor: pointer;
  font-size: 22px;
  font-family: DMSerifDisplay;
}

.siteName > .stub {
  color: var(--orange-primary);
  letter-spacing: 1px;
}

.formAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.formAction span,
.cta span,
.authWrapper p {
  color: var(--neutral-dark-0);
}

.formText {
  cursor: pointer;
  text-align: right;
  font-size: 15px;
  color: var(--blue-dark);
  text-decoration: underline;
  margin-bottom: 8px;
}

.noticeText {
  font-family: 'Poppins';
  font-size: 15px;
  margin: 20px 0;
}

.noticeText a {
  color: var(--purple);
  font-weight: 400;
}

.formBtn {
  margin-bottom: 20px;
  height: 50px;
  font-size: 18px;
}

.preferencesWrapper {
  margin: 20px 0 50px 0;
}

.preferencesWrapper > h5 {
  margin-bottom: 20px;
}

.checkboxGroup input {
  display: inline-block;
  width: 35px;
}

.authWrapper,
.authSuccessWrapper {
  display: grid;
  align-items: center;
  height: calc(100vh - 85px);
}

.authWrapper > div {
  width: 100%;
  max-width: 560px;
}

.iconContainer {
  display: none;
}

.iconContainer svg {
  width: 100%;
  height: auto;
}

.authWrapper > div:nth-child(2) {
  max-width: 560px;
  margin: 0 auto;
  max-height: 100%;
  overflow-y: scroll;
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.authWrapper h1 {
  color: var(--blue-dark);
  font-family: 'DMSerifDisplay';
  margin-bottom: 15px;
}

.authWrapper h1,
.authWrapper h1 > .siteName {
  font-size: 34px;
}

.authWrapper p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 40px;
}

.authWrapper p,
.authWrapper form * {
  font-family: 'Poppins';
}

.wrapperClassName {
  height: 50px;
  padding-left: 5px;
  padding-right: 8px;
  margin-bottom: 10px;
}

.formInput {
  height: 50px;
  padding: 0 10px;
}

.actionBtn {
  background: transparent;
  border: none;
  outline: none;
  color: var(--blue-primary);
}

.checkBoxLabel {
  font-size: 15px;
  color: var(--neutral-dark-0);
}

.gmCheckbox {
  border: 1px solid var(--orange-primary);
}

.checkboxOn {
  background-color: var(--orange-primary);
  border: 1px solid var(--orange-primary);
}

.formWrapper {
  height: calc(100vh - 85px);
  display: grid;
  place-content: center;
  overflow-y: scroll;
}

.authWrapper form {
  min-width: 300px;
  width: 100%;
}

.noMargin {
  margin: 0;
}

.noMargin {
  margin: 0;
}

.inputMargin {
  margin-bottom: 20px;
}

.otpForm {
  padding: 13px;
}

.otpForm button {
  margin-top: 5px;
}

.successScreen {
  max-width: 90%;
  margin: 0 auto;
  box-shadow: 0px 2px 20px 2px #d9d9d9;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
}

.successScreen img {
  width: 100%;
  max-width: 420px;
}

.successScreen .formBtn {
  max-width: 516px;
}

.successScreen p {
  margin-top: 10px;
  margin-bottom: 25px;
  color: #003b71;
  font-family: 'Poppins';
}

/**/
.accountOptionWrapper {
  border: 1px solid var(--neutral-dark-5);
  border-radius: 5px;
  padding: 10px 15px;

  background-color: var(--neutral-dark-6);
  color: var(--neutral-dark-3);

  cursor: pointer;
}

.isActive {
  background-color: var(--blue-light-6);
  border-color: var(--blue-primary);
  color: var(--blue-primary);
}

@media screen and (min-width: 576px) {
  .authWrapper form {
    min-width: 400px;
  }

  .successScreen {
    width: 100%;
    max-width: 500px;
    padding: 30px 50px;
  }
}

@media screen and (min-width: 1024px) {
  .authWrapper h1,
  .authWrapper h1 > .siteName {
    font-size: 42px;
  }

  .iconContainer {
    display: block;
  }

  .formWrapper {
    place-content: inherit;
  }

  .authWrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 90px;
  }

  .authWrapper > div:nth-child(1) {
    width: 100%;
    max-width: 611px;
  }

  .authWrapper > div:nth-child(2) {
    margin-left: 0;
    padding-top: 0;
    padding: 0;
  }

  .formInput {
    height: 70px;
    padding: 0 20px;
  }

  .wrapperClassName {
    height: 70px;
    padding-left: 10px;
    padding-right: 15px;
    margin-bottom: 20px;
  }

  .formBtn {
    height: 70px;
    font-weight: 700;
  }

  .formAction {
    margin: 40px 0;
  }

  .successScreen {
    max-width: 865px;
    box-shadow: 0px 4px 40px 4px #d9d9d9;
    padding: 90px 0;
  }

  .successScreen p {
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 28px;
  }
}
