.datepicker {
  background-color: var(--blue-light-6);
  border: 1px solid var(--blue-light-5);
  border-radius: 50px;
  color: var(--blue-light-3);
}

.wrapper {
  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-dark-5);
  border-radius: 8px;
  padding-bottom: 20px;

  width: 100%;
}

.wrapper header {
  border-bottom: 1px solid var(--neutral-dark-5);
  padding: 15px 20px 10px 20px;
}

.title {
  font-size: 18px;
  font-weight: 500;
}

.noRecord {
  line-height: 100px;
  text-align: center;
}

.historyList {
  max-height: 60vh;
  overflow-y: auto;

  padding: 10px 20px 0px 20px;
}
