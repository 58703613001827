.detailsWrapper {
  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-dark-5);
  border-radius: 8px
}

.detailsWrapper header {
  font-size: 20px;
  font-weight: bold;

  border-bottom: 1px solid var(--neutral-dark-5);
  padding: 20px;
}

.iframeWrapper iframe {
  width: 100%;
  min-height: 80vh;
  background-color: var(--neutral-light);
}

.statSummary {
  padding: 20px;
}

.item {
  margin-bottom: 20px;
}

.item p {
  padding: 0;
  margin: 0;
}

.item > p:first-child {
  color: var(--neutral-dark-3);
  font-size: 12px;
  text-transform: uppercase;
}

.item ul {
  padding: 0;
}

.item li {
  text-align: left;
  margin: 0;
  padding: 0;
  display: inline;
}
