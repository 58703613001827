.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  gap: 30px;
  padding: 20px 0;
}

.option {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: max-content;
  
  cursor: pointer;
}

.iconWrapper {
  width: fit-content;
  padding: 35px;
  
  background-color: var(--neutral-dark-6);
  border: 1px solid var(--neutral-dark-5);
  border-radius: 5px;
  color: var(--neutral-dark-3);
}

.iconWrapper:hover {
  border-color: var(--blue-primary);
  color: var(--blue-primary);
}

.title {
  font-size: 16px;
  font-weight: 'regular';
  text-align: center;
}
