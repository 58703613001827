.greenBadge,
.infoBadge,
.dangerBadge {
  border-radius: 3px;
  padding: 3.5px 10px;
  font-size: 12px;
  color: var(--neutral-light);
}

.greenBadge {
  background: var(--success);
}

.infoBadge {
  background: var(--orange-primary);
}

.dangerBadge {
  background: var(--danger);
}

/**/
.countdownWrapper {
  height: 70vh;
  display: flex;

  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 50px;

  color: var(--neutral-dark-2);
}

.countdownCardWrapper {
  display: flex;
  font-size: 2.5rem;

  justify-content: center;
  align-items: center;
  gap: 20px;
}

.countdownWrapper > h3 {
  font-family: 'DMSerifDisplay';
  font-size: 3.5rem;
  letter-spacing: 2px;
  color: var(--neutral-dark-4);
}

.countdownCard {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  width: 220px;
  height: 200px;

  background-color: #ffffff75;
  border-radius: 8px;
  box-shadow: var(--neutral-dark-5) 2.5px 2.5px 15px 2.5px;
}

.countdownCard > h3 {
  color: var(--orange-primary);
  font-size: 3.5rem;
}

.countdownCard > p {
  font-size: 1.05rem;
  text-transform: uppercase;
  color: var(--neutral-dark-3);
}
