.actionsHeader,
.actionsWrapper {
  display: flex;
  justify-content: space-between;
}

.permissionItem {
  padding: 20px 0 15px 0;
  border-top: 1px solid var(--neutral-dark-6);
}

.actionHeader {
  text-transform: uppercase;
}

.disabled {
  border-color: var(--neutral-dark-5);
  cursor: not-allowed;
}
