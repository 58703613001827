.app-wrapper {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  background-color: #f4f8fb;
}

.app-header {
  height: 8vh;
  width: 100%;

  background-color: rgb(0, 195, 255);
}

.content-wrapper {
  padding: 0 20px;
}

.stat-graph-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  padding: 0px 5px;
}

.stat-graph-wrapper > div:first-child {
  margin-right: 10px;
  margin-left: 0px;
}

.stat-card-pair-wrapper {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 10px; */
}

.stat-card-pair-wrapper > div:nth-child(even) {
  padding-right: 0;
}

.stat-card-pair-wrapper > div:nth-child(odd) {
  padding-left: 0;
  margin-bottom: 10px;
}

.Toastify__toast-container {
  width: 320px;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}

.Toastify__toast--error,
.Toastify__toast--info,
.Toastify__toast--success,
.Toastify__toast--warning {
  border-radius: 5px;
  background: var(--neutral-light);
}

.Toastify__toast--info {
  border: 1px solid #29a7dd;
  border-left: 5px solid #29a7dd;
  color: #29a7dd;
}
.Toastify__toast--success {
  border: 1px solid;
  border-left: 5px solid;
  color: var(--success);
}

.Toastify__toast--warning {
  border: 1px solid;
  border-left: 5px solid;
  color: var(--info);
}

.Toastify__toast--error {
  border: 1px solid;
  border-left: 5px solid;
  color: var(--danger);
}

.gmContainer {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.mobile-screen {
  font-size: 14px;
  color: #8b8b8b;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (min-width: 481px) {
  .content-wrapper {
    padding: 0 30px;
  }

  .graph-wrapper {
    padding: 20px;
  }
}

@media screen and (min-width: 640px) {
  .gmContainer {
    max-width: 640px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .gmContainer {
    max-width: 768px;
  }
  
  .content-area {
    padding: 20px;
  }
  .content-wrapper {
    padding-right: 350px;
    padding-left: 50px;

    overflow-y: scroll;
  }

  .stat-graph-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 8px 0 10px;
  }

  .stat-card-pair-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
  }

  .stat-card-pair-wrapper > div {
    width: 48%;
    padding: 5px;
  }

  .stat-card-pair-wrapper > div:nth-child(odd) {
    margin-bottom: 0;
  }

  .graph-wrapper {
    height: fit-content;
    padding: 30px;
    border-radius: 7.5px;
    background-color: #ffffff;
  }

  .stat-graph-wrapper > div:first-child {
    margin-right: 10px;
    margin-left: -10px;
  }

  .grid {
    display: grid;
    gap: 10px;
  }

  .cols-2 {
    grid-template-columns: 1fr 1fr;
  }

  .cols-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .gmContainer {
    max-width: 1024px;
  }
}

@media screen and (min-width: 1280px) {
  .gmContainer {
    max-width: 1280px;
  }
}

@media screen and (min-width: 1536px) {
  .gmContainer {
    max-width: 1536px;
  }
}
