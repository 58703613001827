.wrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 12.5px;
  background-color: var(--neutral-dark-5);
  border-radius: 10px;
}

.message {
  white-space: pre-line;
}

.error {
  color: var(--red-primary);
  background-color: var(--red-transparent-15);
}
