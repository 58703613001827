.wrapper {
  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-dark-5);
  border-radius: 8px;
  padding: 20px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn {
  background-color: var(--red-primary);
  border-color: var(--red-primary);
}
