.wrapper {
  background-color: var(--neutral-dark-6);
  border: 1px solid var(--neutral-dark-5);
  border-radius: 8px;
  padding: 10px;

  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.codeWrapper {
  flex-grow: 1;
  word-break: break-all;

  font-family: monospace;
}

.codeWrapper {
  display: inline-block;
  color: var(--neutral-dark-2);
  width: 100%;
  white-space: pre-line;
  word-wrap: break-word;
}

.infoText {
  font-size: 12px;
  color: var(--blue-primary);
}
