.senderImageContent {
  display: flex;
  align-items: center;
}

.imageDisplay {
  height: 40px;
  width: 40px;

  border: 1px solid var(--neutral-dark-5);
  border-radius: 50%;
  padding: 5px;
}

.imageDisplay img {
  border-radius: 50%;
}

.label {
  margin: 0;
}

.blue {
  color: var(--blue-primary);
}

.red {
  color: var(--red-primary);
}
