.button {
  width: 100%;
  line-height: 40px;

  border: none;
  border-radius: 6px;
  border: 1px solid var(--blue-primary);

  color: #fff;
  background: var(--blue-primary);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  letter-spacing: 0.5px;
  font-weight: 500;
  outline: none;
}

.btn_secondary,
.moreBtn {
  background-color: #fff;
  color: var(--blue-primary);
  border: 1px solid var(--blue-primary);
}
.btn_danger {
  color: #fff;
  background-color: var(--danger);
  border: 1px solid var(--danger);
}

.btn_secondary {
  display: flex;
  color: var(--blue-primary);
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  background-color: var(--blue-light-6);
  border: 0;
}

.button:disabled {
  color: var(--neutral-light);
  background-color: var(--neutral-dark-4);
  border-color: var(--neutral-dark-4);
  box-shadow: none;
  cursor: not-allowed;
}

.button:focus {
  outline: 0;
}

.btn_secondary:disabled {
  color: var(--neutral-dark-4);
  background-color: var(--neutral-light);
}

.padding {
  padding: 0 10px 0 10px;
}

.icon {
  align-self: center;
}

.text {
  padding-top: 2px;
  padding-right: 10px;
}

.spinner {
  margin-left: 7.5px;
}

.moreBtn {
  padding: 0;
  padding-left: 10px;
  line-height: 32px;
  width: fit-content;
}

.moreBtn .icon {
  margin-left: 5px;
}

.moreBtn .text {
  padding: 0;
}

@media screen and (min-width: 768px) {
  .button {
    min-width: fit-content;
    height: 40px;
  }

  .button:hover {
    opacity: 0.9;
    box-shadow: 2.5px 2.5px 2.5px var(--neutral-dark-4);
    outline: none;
  }
}
