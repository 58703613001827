.wrapper {
  border: 1px solid var(--neutral-dark-5);
  border-radius: 8px;
  background-color: var(--neutral-light);
}

.titleWrapper {
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid var(--neutral-dark-5);
  padding: 10px 20px;
}

.actions {
  display: flex;
  width: fit-content;
  gap: 5px;
}

.switch {
  background-color: var(--blue-transparent);
}

.bodyWrapper {
  display: flex;
  gap: 10px;
  padding: 10px 20px;
}

.actionIcon {
  color: var(--orange-primary);
}

.formModal {
  position: absolute;
}
