.wrapper {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 25px;
}

.text {
  text-align: center;
}
