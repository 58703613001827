.container {
  padding: 20px;
}
#input-file-upload {
  display: none;
}

.pdf {
  margin-left: -5px;
  margin-top: 0.7em;
}
.file {
  font-size: 13px;
  margin-left: 0.7em;
  margin-top: 0.5em;
}
.btn {
  margin-left: 18%;
  cursor: pointer;
}

.attach {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 8px !important;
  margin-top: 0.5em;
}
.error {
  color: var(--danger);
}
@media screen and (min-width: 768px) and (max-width:1280px) {
  .pdf {
    margin-bottom: 1em;
  }
}
@media screen and (min-width: 1028px) {
  .container {
    padding: 20px 30px;
  }
}
