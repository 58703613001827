.list {
  max-height: 80vh;
  overflow-y: scroll;
}

.itemWrapper {
  border: 1px solid var(--neutral-dark-5);
  border-radius: 5px;
  padding: 10px 20px;

  background-color: var(--neutral-dark-6);
  cursor: pointer;
}

.itemWrapperStyled {
  border: 2px solid var(--blue-primary);
  color: var(--blue-primary);
  display: flex;
  justify-content: space-between;
}

.itemWrapper:hover {
  background-color: var(--blue-transparent-15);
  border-color: var(--blue-primary);
}

.switchWrapper {
  width: 100%;
  height: 20vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
