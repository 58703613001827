.headerActions {
  display: flex;
  gap: 15px;
}

.headerBtn {
  border-radius: 50px;
}

.chartCreationModal {
  min-width: unset;
  width: 600px;
}
