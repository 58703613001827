.notifications {
  position: absolute;
  top: 48px;
  right: 0;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  padding: 10px 0;
  width: fit-content;
  border: 1px solid #e5e5e5;
  box-shadow: 5px 5px 15px -2px hsla(0, 0%, 80%, 0.7);
}

.notifications ul {
  width: 100%;
  height: fit-content;
  max-height: 500px;
  margin: 0;
  padding: 10px 30px;
  text-align: center;
  overflow-y: scroll;
  font-size: 15px;
}

.notifications ul::-webkit-scrollbar {
  width: 0;
}

.notifications ul li {
  border-bottom: 1px solid hsla(0, 0%, 80%, 0.7);
  list-style-type: none;
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  cursor: pointer;
}

.notifications li:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.notifications ul li p:last-of-type {
  margin-top: -5px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  font-size: 12px;
}

.notifications ul li:last-child {
  border-bottom: none;
}

.notification-type {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #65b5ff;
  margin-bottom: 8px;
  margin-top: 10px;
}

.notification-message {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #3f3f3f;
  margin-bottom: 10px;
}

.loader-container {
  min-width: 250px;
  padding: 20px;
}

.no-notification {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  padding: 5px;
}
