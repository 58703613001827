.header {
  background-color: var(--neutral-light);
}

.contentArea {
  display: flex;
  padding: 20px;
}

.siteAssetWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.siteName {
  font-family: 'DMSerifDisplay';
  font-size: 24px;
  color: var(--blue-primary);
}

.orangeDot {
  color: var(--orange-primary);
}

.actionWrapper {
  width: auto;
  display: flex;
  align-items: center;
  gap: 15px;
}

.btn {
  width: 250px;
  border-radius: 50px;
}

.logoutBtn {
  color: var(--red-primary);
}
