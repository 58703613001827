.eventForm {
  height: auto;
}

.action {
  grid-template-columns: repeat(2, 1fr);
}

.blueBadge,
.infoBadge {
  border-radius: 3px;
  padding: 3.5px 10px;
  font-size: 12px;
  color: var(--neutral-light);
}

.blueBadge {
  background: var(--blue-primary);
}

.infoBadge {
  background: var(--info);
}

.wrapper .gm-datatable {
  position: unset !important;
}

.noSubWrapper {
  height: 80vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
