.pageContent {
  width: 100%;
  margin-bottom: 20px;
  padding: 0 3%;
}

@media screen and (min-width: 1280px) {
  .pageContent {
    margin: 20px auto;
    padding: 0;
  }
}

@media screen and (min-width: 1440px) {
  .pageContent {
    margin: 20px auto;
  }
}
