.fieldMap {
  display: flex;
  gap: 10px;
  
  align-items: center;
}

.trashIcon {
  color: var(--neutral-dark-3);
}
