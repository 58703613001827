.badge {
  border-radius: 3px;
  padding: 3.5px 10px;
  font-size: 12px;
  background: var(--neutral-dark-6);
  color: var(--neutral-dark-2);
  align-items: center;
}

.blue {
  background: var(--blue-light-6);
  color: var(--blue-primary);
}

.green {
  background: var(--green-transparent-15);
  color: var(--green-primary);
}

.orange {
  background: var(--orange-transparent-15);
  color: var(--orange-primary);
}

.red {
  background: var(--red-transparent-15);
  color: var(--red-primary);
}
