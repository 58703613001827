.wrapper {
  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-dark-5);
  border-radius: 8px;
}

.wrapper header {
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid var(--neutral-dark-5);
  padding: 20px;
  width: 100%;
}

.wrapper h5 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.icon {
  color: var(--red-primary);
}

.body {
  padding: 20px;
  padding-bottom: 25px;
}

.body tr {
  line-height: 45px;
  border-top: 1px solid var(--neutral-dark-5);
}

.body thead {
  line-height: 70px;
  font-size: 18px;
  font-weight: 500;
}

.body thead > td:first-child,
.body tr > th {
  max-width: 350px;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.body td,
.body th {
  padding: 0 10px;
}

.item:nth-child(even) {
  background-color: var(--neutral-dark-6);
}
