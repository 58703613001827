.wrapper {
  border-radius: 8px;
  border: 1px solid var(--neutral-dark-5);
  padding: 15px;

  display: flex;
  justify-content: space-between;

  cursor: pointer;

  background-color: var(--neutral-dark-6);
}

.actions {
  display: flex;
  gap: 10px;

  color: var(--neutral-dark-3);
}

.trashIcon {
  color: var(--red-primary);
}
