.message {
  font-size: 14px;
  color: var(--neutral-dark-3);
  font-weight: 600;
  text-align: center;
  margin: 15px 0;
}

.img {
  width: 100px;
  max-width: 100px;
  height: 60px;
  border-radius: 5px;
}

.payment_info {
  font-size: 13px;
  color: var(--neutral-dark-3);
  margin-bottom: 5px;
}
.payment_sub_info {
  font-size: 14px;
}
