.wrapper {
  line-height: 50px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;

  border-top: 1px solid var(--neutral-dark-5);
}

.wrapper:first-child {
  border-top: unset;
}

.wrapper:hover {
  background-color: var(--blue-transparent);
  color: var(--blue-primary);
  cursor: pointer;
  font-weight: 600;
}

.detailsWrapper {
  max-height: 600px;
  overflow-y: auto;
}

.detailsPanel {
  width: 100%;
}
