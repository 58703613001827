.formWrapper {
  width: 500px;
}

#selectorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.selectionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: var(--neutral-dark-2);
  cursor: pointer;
  border: 1px solid var(--neutral-dark-5);
  border-radius: 8px;
  padding: 20px;
  width: 120px;
  height: 120px;
}

.disabled {
  background-color: var(--neutral-dark-6);
  cursor: not-allowed;
}

.delay {
  color: var(--neutral-dark-3);
}

.trigger {
  color: var(--orange-primary);
}

.action {
  color: var(--green-primary);
}

.timepicker {
  z-index: 0;
}
