.profileTop {
  background-color: #9f9f9f;
  padding: 50px 0;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.profileTop + div {
  border-radius: 0px;
}
.profileTop::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.profilePicture {
  display: flex;
  justify-content: center;
  width: 130px;
  height: 130px;
  overflow: hidden;
  z-index: 1;
  border-radius: 50%;
}

.editIcon {
  position: absolute;
  bottom: 55px;
  right: 75px;
  background-color: #fff;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.UpdateProfilePicture {
  display: flex;
  justify-content: center;
  width: 130px;
  height: 130px;
  overflow: hidden;
  z-index: 1;
  border-radius: 50%;
}

.UpdateEditIcon {
  position: absolute;
  bottom: 0;
  right: 20px;
  cursor: pointer;
}
.UpdateEditIcon div {
  display: flex;
  justify-content: flex-end;
}
.UpdateEditIcon svg {
  color: #fff;
  margin: 8px;
}
.editIcon svg {
  width: 35px;
  height: 30px;
}
