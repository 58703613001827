.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 10px 20px;

  line-height: 24px;
}

.wrapper p {
  margin: 0;
  padding: 0;
}

.error {
  color: var(--neutral-light);
  background-color: var(--danger);
}

.info {
  color: var(--neutral-light);
  background-color: var(--info);
}
